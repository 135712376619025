import React from 'react';
import { useMatch } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';

const Fallback: React.FC = () => {
  const isHomepage = !!useMatch('/');
  return (
    <>
      <MainLayout
        header={true}
        footer={true}
        bg={isHomepage ? 'ivory' : 'alabaster'}
      ></MainLayout>
    </>
  );
};

export default Fallback;
