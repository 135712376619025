export const menus = [
  {
    label: 'スキンケア',
    href: '/category/1'
  },
  {
    label: 'フレグランス',
    href: '/category/2'
  },
  {
    label: 'ハンドケア',
    href: '/category/3'
  },
  {
    label: 'ボディケア',
    href: '/category/4'
  },
  {
    label: 'ホーム',
    href: '/category/5'
  },
  {
    label: 'ヘアケア',
    href: '/category/6'
  },
  {
    label: 'キット',
    href: '/category/7'
  },
  {
    label: 'パーソナルケア',
    href: '/category/8'
  }
];
