import { Box, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import { menus } from '../lib/menus';

const SwiperMoveTo: React.FC = () => {
  const swiper = useSwiper();
  useEffect(() => {
    const currentIndex = menus.findIndex(
      (menu) => menu.href === location.pathname
    );
    swiper.slideTo(currentIndex, 300);
  }, [location.pathname]);
  return <></>;
};

const CategoryTabMenu: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (idx: number) => {
    navigate(menus[idx].href);
  };

  return (
    <Box
      w="100%"
      bg="categoryMenu"
      h="52px"
      mt="50px"
      position="sticky"
      py="1px"
      top={0}
      zIndex={30}
      color="aesopBlack.secondary"
      whiteSpace="nowrap"
    >
      <Swiper
        slidesPerView={'auto'}
        centeredSlides={false}
        spaceBetween={-12}
        grabCursor={true}
        slideToClickedSlide={true}
      >
        <SwiperMoveTo />
        {menus.map((menu, idx) => (
          <SwiperSlide
            key={idx}
            style={{
              width: 'auto',
              textAlign: 'center',
              height: '100%',
              padding: '12px 20px'
            }}
            onClick={() => handleNavigate(idx)}
          >
            <Text
              color="aesopBlack.secondary"
              sx={
                menu.href === location.pathname
                  ? { pb: '2px', borderBottom: '1px', borderColor: '#666666' }
                  : {}
              }
              me={idx === menus.length - 1 ? '16px' : 0}
            >
              {menu.label}
            </Text>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default CategoryTabMenu;
