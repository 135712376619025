export const LIFF_ID = process.env.REACT_APP_LIFF_ID as string;
export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const GA_TRACKING_ID = process.env.REACT_APP_GTM_ID;

export const API_RESPONSE_STATUS = {
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
};

export const topMessages = [
  {
    heading: 'イソップについて',
    body: 'イソップは1987年にオーストラリアのメルボルンで創業しました。私たちは創業当初から現在に至るまで、品質の高い製品をお届けすることを常に目標としています。スキンケア、ボディケア、ヘアケア製品のほか、フレグランスやパーソナルケア、ホーム製品等もご用意しております。\n製品には安全性や効能、使い心地の良さなどを考慮しながら研究を重ねた、こだわりの植物由来成分と非植物由来成分を使用しており、個性豊かな各ストアでは専門のコンサルタントがお客様に合った製品選びのお手伝いをいたします。\nまた、イソップでは私たちに気づきや学び、会話のきっかけを与えてくれる芸術を支援する活動も行っております。イソップの製品は、公式オンラインストアをはじめ、世界の主要都市で展開している直営店や百貨店カウンターでお求めいただけます。'
  },
  {
    heading: '製品カタログについて',
    body: 'この製品カタログでは、イソップの全製品の情報を掲載しております。お気に入りの製品を探したり、製品を「マイリスト」に保存してご来店の際にコンサルタントとの会話にご活用ください。\nまずはこのページの上のメニューバーからお好きなカテゴリーをお選びください。肌タイプや肌悩みでフィルタをかけることもできます。\nお気に入りの製品が見つかったら、 「マイリスト」に保存しましょう。リストは、お好みに合わせて名前をつけたり、複数作成することが出来ます。保存したリストは後から見返したり、ご友人にシェアしたり、店頭でのコンサルタントとの会話でご活用ください。'
  }
];

export const DEFAULT_WISHLIST_NAME = '固定リスト';

export const SHARE_WISH_LIST_TEXTS = {
  title: 'イソップ 製品 マイリストが届きました',
  description: '下記の「マイリストを見る」のボタンをタップしてご確認ください。',
  buttonLabel: 'マイリストを見る'
};

export const SHARE_SINGLE_PRODUCT_TEXTS = {
  title: 'イソップの製品が共有されました',
  description: '下記のボタンをタップすると詳細をご確認いただけます。',
  buttonLabel: '製品を見る'
};
