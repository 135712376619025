import { Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import CategoryTabMenu from '../components/CategoryTabMenu';
import Footer from '../components/Footer';
import Header from '../components/Header';

const MainLayout: React.FC<{
  header?: boolean;
  footer?: boolean;
  className?: string;
  bg?: string;
  categoryTabMenu?: true;
  children?: ReactNode;
}> = ({ header, footer, className, bg, categoryTabMenu, children }) => {
  let classList = '';
  className && (classList += ` ${className}`);
  footer && (classList += '');

  return (
    <Flex bg={bg} flexDir="column">
      {header && <Header />}
      <Flex
        id="ContainerElementID" // 単品共有URLアクセス時、共有された製品の位置まで自動スクロールさせるために設定
        flexDir="column"
        bg={bg}
        h="100vh"
        pt={categoryTabMenu ? '0' : '52px'}
        pb="80px"
        className={classList}
        overflowY="scroll"
      >
        {categoryTabMenu && <CategoryTabMenu />}
        {children}
      </Flex>

      {footer && <Footer />}
    </Flex>
  );
};
export default MainLayout;
