import { Box, Text, Divider } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { numberOfWishlists } from '../App';

const Footer: React.FC = () => {
  const countWishlists = useRecoilValue(numberOfWishlists);

  const navigate = useNavigate();
  const location = useLocation();

  const isActiveLink = (href: string) =>
    location.pathname.split('/')[1] === href.split('/')[1];

  return (
    <Box
      position="fixed"
      bottom="0"
      display="flex"
      flexDir="row"
      w="100%"
      h="80px"
      bg="aesopBlack.primary"
    >
      <Box w="50%" as="button" onClick={() => navigate('/')}>
        <Text
          color="alabaster"
          display="inline"
          sx={
            isActiveLink('/')
              ? { py: '3px', borderBottom: '1px', borderColor: 'alabaster' }
              : {}
          }
        >
          トップ
        </Text>
      </Box>
      <Divider
        orientation="vertical"
        my="18px"
        h="44px"
        colorScheme="alabaster"
      />
      <Box
        w="50%"
        as="button"
        color="alabaster"
        onClick={() => navigate('/wishlists')}
      >
        <Text
          color="alabaster"
          display="inline"
          sx={
            isActiveLink('/wishlists')
              ? { py: '3px', borderBottom: '1px', borderColor: 'alabaster' }
              : {}
          }
        >
          マイリスト{!countWishlists ? '' : ` (${countWishlists})`}
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
