import React from 'react';
import { createRoot } from 'react-dom/client';
import liff from '@line/liff';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import { theme } from './lib/chakra.config';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const { ToastContainer } = createStandaloneToast({ theme });

liff
  .init({
    liffId: process.env.REACT_APP_LIFF_ID || '',
    withLoginOnExternalBrowser: true
  })
  .then(() => {
    root.render(
      <React.StrictMode>
        <ChakraProvider theme={theme}>
          <App />
          <ToastContainer />
        </ChakraProvider>
      </React.StrictMode>
    );
  })
  .catch((e) => {
    console.log('Error Initializing LIFF: ', e.message);
    localStorage.clear();
    liff.login();
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
