import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  fonts: {
    body: 'Helvetica Neue, Noto Sans JP, system-ui, sans-serif',
    heading: 'Helvetica Neue, Noto Sans JP, system-ui, sans-serif'
  },
  colors: {
    aesopBlack: {
      primary: '#333333',
      secondary: '#666666'
    },
    border: 'rgba(51, 51, 51, 0.2)',
    categoryMenu: '#ebeade',
    categoryGrid: '#f6f5e8',
    searchConfig: '#f0efe1',
    alabaster: '#fffef2',
    ivory: '#f5ece4'
  }
});
