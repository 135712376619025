import {
  Box,
  Button,
  Fade,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ReactComponent as BrandLogo } from '../assets/logo/brand.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as LeftArrow } from '../assets/icons/leftArrow.svg';
import { ReactComponent as RightArrow } from '../assets/icons/rightArrow.svg';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const handleShowSearch = () => setShowSearch(true);
  const handleHideSearch = () => setShowSearch(false);

  return (
    <>
      <Box
        display="flex"
        position="fixed"
        alignItems="center"
        justifyContent="space-between"
        bg="aesopBlack.primary"
        px="20px"
        w="100%"
        h="50px"
        zIndex={10}
      >
        <Link to="/">
          <BrandLogo />
        </Link>

        {/* NOTE: Postpone the development of search configuration on phase2. */}
        <Box display="none" as="button" onClick={handleShowSearch}>
          <SearchIcon />
        </Box>
      </Box>
      <Fade in={showSearch} unmountOnExit={true}>
        <Box
          w="100vw"
          h="100vh"
          mt="-52px"
          bg="rgba(51, 51, 51, 0.95)"
          position="fixed"
          px="20px"
          display="flex"
          flexDir="column"
          zIndex="20"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            h="50px"
            zIndex={10}
          >
            <BrandLogo />
          </Flex>
          <Flex
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            h="52px"
            mb="85.6px"
            zIndex={10}
          >
            <Flex as="button" alignItems="center" onClick={handleHideSearch}>
              <LeftArrow />
              <Text ml="12px" color="alabaster">
                戻る
              </Text>
            </Flex>
          </Flex>
          <InputGroup mb="25px">
            <Input
              type="text"
              color="alabaster"
              variant="flushed"
              _focus={{ boxShadow: 'none' }}
            />
            <InputRightElement width="2.5rem">
              <Button
                bg="transparent"
                size="sm"
                pe="0"
                _hover={{ bg: 'transparent' }}
                _focus={{ bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
              >
                <RightArrow />
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
      </Fade>
    </>
  );
};

export default Header;
