import React, { ReactNode, Suspense, useLayoutEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { atom, RecoilRoot } from 'recoil';
import './assets/styles/App.scss';
import GoogleAnalytics from './lib/GoogleAnalytics';
import Fallback from './pages/Fallback';

const Catalog = React.lazy(() => import('./pages/Catalog'));
const CommonError = React.lazy(() => import('./pages/CommonError'));
const Wishlist = React.lazy(() => import('./pages/Wishlist'));
const WishlistProducts = React.lazy(() => import('./pages/WishlistProducts'));

const ScrollToTop = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return <>{children}</>;
};

export const numberOfWishlists = atom({
  key: 'numberOfWishlists',
  default: 0
});

// Component for Error fallback
const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary
}) => {
  return (
    <div role="alert">
      <p>コンポーネント読み込み時にエラーが発生しました</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>再読み込み</button>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <HelmetProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL as string}>
          <ScrollToTop>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<Fallback />}>
                <Routes>
                  {/* トップタブ */}
                  <Route path="/" element={<Catalog />} />
                  <Route path="/category" element={<Catalog />} />
                  <Route path="/category/:categoryId" element={<Catalog />} />

                  {/* マイリストタブ */}
                  <Route path="/wishlists" element={<Wishlist />} />
                  <Route path="/wishlists/:id" element={<WishlistProducts />} />
                  <Route
                    path="/wishlists/:id/shared"
                    element={<WishlistProducts />}
                  />

                  {/* 共通 */}
                  <Route path="/expired-idtoken" element={<CommonError />} />
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </ScrollToTop>
          <GoogleAnalytics />
        </BrowserRouter>
      </HelmetProvider>
    </RecoilRoot>
  );
};

export default App;
